import Vue from 'vue'
import VueRouter from 'vue-router'
import store from '../store/index.js'
import firebase from "firebase";
// import Home from '../views/Home.vue'


//  const Dashboard = () => import('../components/Dashboard.vue')
 const Dashboard = () => import('../components/NewDashboard.vue')
 const HomeDashboard = () => import('../components/HomeDashboard.vue')
 const PreguntasFrecuentes = () => import('../components/PreguntasFrecuentes.vue')
 const DashboardFCV = () => import('../components/DashboardFCV.vue')
 const HistorialCargas = () => import('../components/HistorialCargas.vue')
 const Ingreso   = () => import('../components/Ingreso.vue')
 const Login   = () => import('../components/Login.vue')
 const Account   = () => import('../components/Account.vue')
const Verify = () => import('../views/Verify.vue')
const NotVerified = () => import('../views/NotVerified.vue')
 const Crud   = () => import('../views/Crud.vue')
const Offline = () => import('../views/Offline.vue')
const CrudCompanies = () => import('../views/CrudCompanies.vue')
const txsuccess = () => import('../views/Txsuccess.vue')
const txcancel = () => import('../views/Txcancel.vue')
 // import Register from '../components/Register'

//const Maps = () => import('./Maps.vue')

// global registration
// import VueFormWizard from 'vue-form-wizard'
// import 'vue-form-wizard/dist/vue-form-wizard.min.css'
// Vue.use(VueFormWizard)
// registration wizard


Vue.use(VueRouter)

const routes = [
  {
    path: '/HomeDashboard',
    name: 'HomeDashboard',
    component: HomeDashboard,
    meta: {
      requiresAuth: true}
  },
  {
    path: '/PreguntasFrecuentes',
    name: 'PreguntasFrecuentes',
    component: PreguntasFrecuentes,
    meta: {
      requiresAuth: true}
  },
  {
    path: '/dashboard',
    name: 'dashboard',
    component: Dashboard,
    meta: {
      requiresAuth: true}
  },
   {
    path: '/dashboardFCV',
    name: 'dashboardFCV',
    component: DashboardFCV,
    meta: {
      requiresAuth: true}
  },
  {
    path: '/HistorialCargas',
    name: 'HistorialCargas',
    component: HistorialCargas,
    meta: {
      requiresAuth: true}
  },
  {
    path: '/crud',
    name: 'crud',
    component: Crud,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/crudcompanies',
    name: 'crudcompanies',
    component: CrudCompanies,
    meta: {
      requiresAuth: true
    }
  },
  {
    path: '/txsuccess',
    name: 'txsuccess',
    component: txsuccess
  },
  {
    path: '/txcancel',
    name: 'txcancel',
    component: txcancel
  },
   {
    path: '/login',
    name: 'login',
     component: Login,
     meta: {
       requiresAuth: false,
       outIfLoggedin:true
     }
  },
   {
    path: '/verify',
    name: 'verify',
     component: Verify,
     meta: {
       requiresAuth: false,
       outIfLoggedin:false
     }
  },
  {
    path: '/notverified',
    name: 'notverified',
    component: NotVerified,
    meta: {
      requiresAuth: true,
      outIfLoggedin: false
    }
  },
  // {
  //       path: '/register',
  //       name: 'Register',
  //       component: Register
  //   },
  {
    path: '/',
    name: 'ingreso',
    component: Ingreso,
    meta: {
      requiresAuth: true
    } },
  {
    path: '/offline',
    name: 'offline',
    component: Offline,
    meta: {
      requiresAuth: false
    }
  },
    {
    path: '/account',
    name: 'account',
    component: Account,
    meta: {
      requiresAuth: true
    },
    
    
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    
 //   component: () => import(/* webpackChunkName: "about" */ '../views/About.vue')
  }
]

const router = new VueRouter({
  mode: 'history',
  routes
 
})

router.beforeEach((to, from, next) => {
  console.log("to:"+to.name)
  const requiresAuth = to.matched.some(record => record.meta.requiresAuth)
  const outIfLoggedin = to.matched.some(record => record.meta.outIfLoggedin)
  const isAuthenticated = firebase.auth().currentUser
  console.log(store)
  // var isAuthenticated= store.getters.user.loggedIn
  // console.log(store.getters.user.loggedIn)
  
  // console.log(isAuthenticated)
  console.log("requieres auth?")
  console.log(requiresAuth)
    console.log("Authenticated??")
  console.log(isAuthenticated)

// forces the account info completition
  
//   if (store != undefined && store != null && store.getters != null && store.getters.user.datosFb!= null  && store.getters.user.datosFb.status=="pending" && router.currentRoute!="/account") {
//     console.log("dddddd")
//     //next('/account')
//   }
//   else {
//     console.log("cuenta activa. Continua")
//   }
// // forces..

   if (requiresAuth && !isAuthenticated) {
    next('/login')
  } 
   else {
     next()
   }
  //next()
})


export default router
