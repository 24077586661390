import Vue from 'vue'
import App from './App.vue'
import store from './store'
import router from './router/index.js'

import Vuetify from 'vuetify'
import 'vuetify/dist/vuetify.min.css'
import './assets/vuetifyJuanAndres.css'
import vuetify from './plugins/vuetify'

import * as firebase from 'firebase'
import { firestorePlugin } from 'vuefire'
import DatetimePicker from 'vuetify-datetime-picker'

// new
// Vue.prototype.$workbox = wb

// import Meta from 'vue-meta'
Vue.use(DatetimePicker)
// Vue.use(Meta)
// import { Datetime } from 'vue-datetime'
// import 'vue-datetime/dist/vue-datetime.css'
// Vue.use(Datetime)
// Vue.component('datetime', Datetime)

Vue.use(firestorePlugin)

Vue.use(Vuetify)

Vue.config.productionTip = false

// const configOptionsBeta = {
//   apiKey: 'AIzaSyCfYUHG3cnGUpjVmGIOk7JxDcI1bWGTh88',
//   authDomain: 'rayo-portal-santander-beta.firebaseapp.com',
//   databaseURL: 'https://rayo-portal-santander-beta.firebaseio.com',
//   projectId: 'rayo-portal-santander-beta',
//   storageBucket: 'rayo-portal-santander-beta.appspot.com',
//   messagingSenderId: '452563493428',
//   appId: '1:452563493428:web:8efac7c12620dc7b512061',
//   measurementId: 'G-LZBPRMLHLB'
// }
// 
const configOptions = {
  apiKey: 'AIzaSyDlXXRykQOlEvZmrJW_lIiFz7AqdsiiZjk',
  authDomain: 'entregasrayo.firebaseapp.com',
  databaseURL: 'https://entregasrayo.firebaseio.com',
  projectId: 'entregasrayo',
  storageBucket: 'entregasrayo.appspot.com',
  messagingSenderId: '338070330126',
  appId: '1:338070330126:web:cd4922b024d627e33d955f',
  measurementId: 'G-RGB5HJ0B1Q'
}

firebase.initializeApp(configOptions)

// const messaging = firebase.messaging()

var datosUserGo = {}
var perfilEmpresaGo = {}
var localArrayCecosOwner = []
var userGo = {}

var promisesFB = []
firebase.auth().onAuthStateChanged(user => {
  promisesFB = []
  var self = this
  if (user != null && user != undefined) {
    userGo = user
    // console.log(self)
    console.log('Cambió estado, user==true∫ , exito')
    if (user != null & user.emailVerified == false && router.currentRoute.name != 'verify' && router.currentRoute.name != 'notverified') {
      // console.log(router)  
      router.push({
        name: 'notverified'
      })
    } else {

      // DATOS USERFB
      // promisesFB.push()
      firebase.firestore().collection('portalUsers').doc(user.uid).onSnapshot(snapshot => {
        console.log(1)
        datosUserGo = snapshot.data()
        // DATOS PERFIL EMPRESA
        // promisesFB.push(
        firebase
          .firestore()
          .collection('perfilEmpresa')
          .doc(datosUserGo.perfilEmpresa)
          .onSnapshot(function (doc) {
            console.log(2)
            if (doc.exists) {
              perfilEmpresaGo = doc.data()
            }
            // DATOS CECOS OWNER

            // promisesFB.push(
            firebase.firestore()
              // .collection('ownerCecos').doc(store.getters.user.data.uid).onSnapshot(function (qss) {
              .collection('ownerCecos').doc(datosUserGo.uid).onSnapshot(function (qss) {
              console.log(3)
              if (qss.exists) {
                localArrayCecosOwner = qss.data().cecos
              }
              console.log(localArrayCecosOwner)

              console.log('fin')
              store.dispatch('fetchUser', userGo)
              store.dispatch('fetchUserDatos', datosUserGo)
              store.dispatch('setCecosOwner', localArrayCecosOwner)
              store.dispatch('setPerfilEmpresa', perfilEmpresaGo)
              // checkPayment(store.getters.user.datosFb.userPagoPendiente)

              if (router.currentRoute.name != 'account' && router.currentRoute.name != 'verify') {
                router.push({
                  name: 'HomeDashboard'
                })
              }
            },
              function (error) {
                console.log('error en conseguir cecos del Owner')
                console.log(error)
              })
          //   )
          },
            function (error) {
              console.log('error en conseguir PERFIlEMPRESA')
              console.log(error)
            })
      // )
      }, function (error) {
        console.log(user)
        console.log('error en conseguir DATOSFB')
        console.log(error)
      }

      )

      // console.log('INICIA PROMISES')
      // Promise.all(promisesFB).then(function (res) {
      //   console.log(res)

    // // console.log(res)
    // // res[0] is your experience_id snapshot
    // // res[1] is your from_UID snapshot
    // // Do something...
    // })
    }
  }else {
    console.log('no hay user')
    store.dispatch('fetchUser', null)
  }
})

Vue.config.productionTip = false

new Vue({
  Vuetify,
  store,
  router,
  vuetify,
  render: h => h(App),
firebase}).$mount('#app')
