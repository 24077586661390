var render = function render(){var _vm=this,_c=_vm._self._c;return _c('v-app',[_c('v-navigation-drawer',{attrs:{"temporary":"","app":"","clipped":"","bottom":""},model:{value:(_vm.drawer),callback:function ($$v) {_vm.drawer=$$v},expression:"drawer"}},[(_vm.user.data != null)?_c('v-row',{attrs:{"align":"start"}},[_c('v-col',{staticClass:"pa-0",attrs:{"align-self":"start","cols":"12"}}),_c('v-col',{staticClass:"py-5"},[_c('v-sheet',{staticClass:"mx-auto",attrs:{"tile":true,"color":_vm.user.perfilEmpresa.color,"height":"100%","width":"100%"}},[(
              _vm.user != undefined &&
              _vm.user.datosFb != undefined &&
              _vm.user.data != undefined
            )?_c('v-row',{staticClass:"lightbox white--text px-2",attrs:{"align":"start"}},[_c('v-col',{attrs:{"cols":"3"}},[(_vm.user.photoURL != null)?_c('v-avatar',{attrs:{"tile":"","size":"34px"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-img',{attrs:{"alt":"Avatar","src":_vm.user.photoURL}})],1):(
                  _vm.user.perfilEmpresa != null &&
                  _vm.user.perfilEmpresa.logo != null
                )?_c('v-avatar',{attrs:{"tile":"","size":"auto","width":"100%","color":"white"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-img',{attrs:{"src":_vm.user.perfilEmpresa.logo}})],1):_c('v-avatar',{attrs:{"tile":"","size":"34px"},on:{"click":function($event){_vm.drawer = !_vm.drawer}}},[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-circle")])],1)],1),_c('v-col',{attrs:{"cols":"9"}},[_c('h4',{staticClass:"mb-0"},[_vm._v(_vm._s(_vm.user.datosFb.displayName))])])],1):_vm._e()],1),(
            _vm.user != undefined &&
            _vm.user.datosFb != undefined &&
            _vm.user.data != undefined
          )?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"warning"}},[_vm._v("mdi-email")]),_c('strong',[_vm._v("Email:")]),_vm._v(" "+_vm._s(_vm.user.data.email)+" ")],1)],1)],1):_vm._e(),(
            _vm.user != undefined &&
            _vm.user.datosFb != undefined &&
            _vm.user.data != undefined
          )?_c('v-list-item',[_c('v-list-item-content',[_c('v-list-item-subtitle',[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"teal"}},[_vm._v("mdi-book-account")]),_vm._v(" "+_vm._s(_vm.user.data.uid)+" ")],1)],1)],1):_vm._e()],1)],1):_vm._e(),_c('v-divider'),(
        _vm.user.datosFb != undefined &&
        _vm.user != null &&
        _vm.user.data != undefined &&
        _vm.user.data != null &&
        _vm.user.data.emailVerified == true
      )?_c('v-list',{attrs:{"dense":""}},[_c('v-list-item',{attrs:{"link":"","to":"/HomeDashboard"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-tablet-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Home")])],1)],1),_c('v-list-item',{attrs:{"link":"","to":"/"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-package-variant")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Iniciar Pedidos")])],1)],1),(_vm.user.datosFb.firebaseRTDB == true)?_c('v-list-item',{attrs:{"link":"","to":"/dashboardFCV"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Historial de Cargas")])],1)],1):_vm._e(),(
          _vm.user.perfilEmpresa.cargaMasiva &&
          _vm.user.perfilEmpresa.customer_id == '612415621c9f31faeee98ff9'
        )?_c('v-list-item',{attrs:{"link":"","to":"/HistorialCargas"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-cloud-upload")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Historial de Cargas")])],1)],1):_vm._e(),(_vm.user.datosFb.firebaseRTDB == true)?_c('v-list-item',{attrs:{"link":"","to":"/HistorialCargas"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-monitor-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Historial de Pedidos")])],1)],1):_c('v-list-item',{attrs:{"link":"","to":"/dashboard"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-monitor-dashboard")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Historial de Pedidos")])],1)],1),(
          (_vm.user.datosFb.perfil != 'propios') &
          (_vm.user.datosFb.perfil != 'cecos')
        )?_c('v-list-item',{attrs:{"link":"","to":"/crud"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account-cog")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Administración de Usuarios")])],1)],1):_vm._e(),(
          (_vm.user.datosFb.perfil != 'propios') &
          (_vm.user.datosFb.perfil != 'cecos')
        )?_c('v-list-item',{attrs:{"to":"/crudcompanies"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account-details")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Administración de Perfiles Empresas")])],1)],1):_vm._e(),_c('v-list-item',{attrs:{"link":"","to":"/account"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-account")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Mi cuenta")])],1)],1),_c('v-list-item',{attrs:{"link":"","to":"/PreguntasFrecuentes"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-forum")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Preguntas Frecuentes")])],1)],1),_c('v-list-item',{attrs:{"link":"","target":"_blank","href":"https://rayoapp.com"},on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-flash")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("Acerca de Rayo")])],1)],1),(_vm.user.datosFb.thor)?_c('v-list-item',{on:{"click":function($event){_vm.drawer = false}}},[_c('v-list-item-action',[_c('v-icon',[_vm._v("mdi-hammer")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v("FM")])],1)],1):_vm._e()],1):_vm._e(),(
        _vm.user != undefined &&
        _vm.user.datosFb != undefined &&
        _vm.user.datosFb.perfil != 'propios' &&
        _vm.user.datosFb.perfil != 'cecos'
      )?_c('p',{staticClass:"caption"}):_vm._e(),(_vm.user.data != null)?_c('div',{staticClass:"pa-2"},[_c('v-btn',{attrs:{"color":"#311def","dark":"","block":""},on:{"click":function($event){return _vm.cerrarSesion()}}},[_vm._v("Cerrar Sesión")])],1):_vm._e()],1),(['login'].indexOf(_vm.$route.name) === -1)?_c('v-app-bar',{attrs:{"app":"","clipped-left":"","dense":""}},[_c('v-app-bar-nav-icon',{on:{"click":function($event){_vm.drawer = !_vm.drawer}}}),_c('v-img',{attrs:{"contain":"","max-width":"120","src":"https://www.rayoapp.com/img/2020/logo_azul.png"}}),_c('v-spacer'),(_vm.user.photoURL != null)?_c('span',[_c('v-img',{attrs:{"contain":"","max-width":"80","src":_vm.user.photoURL}})],1):(
        _vm.user.perfilEmpresa != null && _vm.user.perfilEmpresa.logo != null
      )?_c('span',[_c('v-img',{attrs:{"contain":"","max-width":"50","src":_vm.user.perfilEmpresa.logo}})],1):_c('span',[_c('v-icon',{attrs:{"color":"primary"}},[_vm._v("mdi-account-circle")])],1),(_vm.user.dataFb != null && _vm.user.dataFb.displayName != undefined)?_c('span',{staticClass:"overline mb-4"},[_vm._v(_vm._s(_vm.user.dataFb.displayName))]):_vm._e()],1):_vm._e(),_c('v-main',[_c('v-container',{staticClass:"mx-0 px-0",attrs:{"fluid":""}},[_c('router-view')],1)],1),_c('v-footer',{staticStyle:{"color":"white"},attrs:{"color":"#122337","app":""}},[_c('span',[_c('a',{staticStyle:{"color":"white"},attrs:{"href":"https://rayoapp.com"}},[_vm._v("Rayo LATAM")]),_vm._v(" 2023 - v1.45 ")])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }