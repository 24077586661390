<template>
  <v-app>
    <!-- BARRA DE NAVEGACION -->
    <v-navigation-drawer temporary v-model="drawer" app clipped bottom>
      <!-- <v-card v-if="user.data != null" class="mx-auto" max-width="434" tile color="transparent"> -->
      <!-- <v-img src="https://cdn.vuetifyjs.com/images/cards/server-room.jpg"> -->
      <v-row v-if="user.data != null" align="start">
        <v-col align-self="start" class="pa-0" cols="12"></v-col>
        <v-col class="py-5">
          <!-- <v-list-item>
              <v-list-item-avatar v-if="user.data.photo==null">
               
                <v-icon color="primary">mdi-account-circle</v-icon>
              </v-list-item-avatar>
              <v-list-item-avatar v-else>
                <v-img :src="user.data.photo"></v-img>
              </v-list-item-avatar>

              <v-list-item-content>
                <v-list-item-title class="title">{{ user.data.displayName }}</v-list-item-title>
              </v-list-item-content>
          </v-list-item>-->
          <!-- <v-img :aspect-ratio="16/9" max-height="120" :src="user.perfilEmpresa.navigationImg">
            HOLAAA{{user.perfilEmpresa.navigationImg}}
          <v-img contain class="mt-2" width="25%" src="./assets/white_logo.png"></v-img>-->

          <v-sheet
            :tile="true"
            class="mx-auto"
            :color="user.perfilEmpresa.color"
            height="100%"
            width="100%"
          >
            <v-row
              v-if="
                user != undefined &&
                user.datosFb != undefined &&
                user.data != undefined
              "
              align="start"
              class="lightbox white--text px-2"
            >
              <!-- :color="user.perfilEmpresa.navigationImg -->

              <v-col cols="3">
                <v-avatar
                  tile
                  @click="drawer = !drawer"
                  size="34px"
                  v-if="user.photoURL != null"
                >
                  <v-img alt="Avatar" :src="user.photoURL"></v-img>
                </v-avatar>

                <v-avatar
                  tile
                  @click="drawer = !drawer"
                  size="auto"
                  width="100%"
                  color="white"
                  v-else-if="
                    user.perfilEmpresa != null &&
                    user.perfilEmpresa.logo != null
                  "
                >
                  <v-img :src="user.perfilEmpresa.logo"></v-img>
                </v-avatar>
                <v-avatar tile @click="drawer = !drawer" size="34px" v-else>
                  <v-icon color="primary">mdi-account-circle</v-icon>
                </v-avatar>
              </v-col>
              <v-col cols="9">
                <h4 class="mb-0">{{ user.datosFb.displayName }}</h4>
              </v-col>
            </v-row>
          </v-sheet>
          <!-- </v-img> -->

          <v-list-item
            v-if="
              user != undefined &&
              user.datosFb != undefined &&
              user.data != undefined
            "
          >
            <v-list-item-content>
              <!-- <v-list-item-title class="title">{{ user.data.displayName }}</v-list-item-title> -->

              <v-list-item-subtitle>
                <v-icon class="mr-2" color="warning">mdi-email</v-icon>
                <strong>Email:</strong>
                {{ user.data.email }}
              </v-list-item-subtitle>

              <!-- <v-list-item-subtitle>
                <v-icon color="primary" class="mr-2">mdi-information</v-icon>
                <strong>UID:</strong>
                {{ user.data.uid }}
              </v-list-item-subtitle>
              <v-list-item-subtitle>
                <v-icon color="green darken-2" class="mr-2">mdi-information</v-icon>
                <strong>Email Verificado:</strong>
                {{ user.data.emailVerified ? "Si" : "No"}}
              </v-list-item-subtitle>-->
            </v-list-item-content>
          </v-list-item>

          <v-list-item
            v-if="
              user != undefined &&
              user.datosFb != undefined &&
              user.data != undefined
            "
          >
            <v-list-item-content>
              <!-- <v-list-item-title class="title">{{ user.data.displayName }}</v-list-item-title> -->

              <v-list-item-subtitle>
                <v-icon class="mr-2" color="teal">mdi-book-account</v-icon>
                {{ user.data.uid }}
              </v-list-item-subtitle>
            </v-list-item-content>
          </v-list-item>
        </v-col>
      </v-row>
      <!-- </v-img> -->
      <!-- </v-card> -->
      <v-divider></v-divider>

      <!-- <div
        v-else
        class="ma-3"
      >Datos no disponibles. Por favor contacte a soporte en el chat de la ventana inferior</div>-->

      <v-list
        v-if="
          user.datosFb != undefined &&
          user != null &&
          user.data != undefined &&
          user.data != null &&
          user.data.emailVerified == true
        "
        dense
      >
        <v-list-item @click="drawer = false" link to="/HomeDashboard">
          <v-list-item-action>
            <v-icon>mdi-tablet-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Home</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="drawer = false" link to="/">
          <v-list-item-action>
            <v-icon>mdi-package-variant</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Iniciar Pedidos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="user.datosFb.firebaseRTDB == true"
          @click="drawer = false"
          link
          to="/dashboardFCV"
        >
          <v-list-item-action>
            <v-icon>mdi-cloud-upload</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Historial de Cargas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="
            user.perfilEmpresa.cargaMasiva &&
            user.perfilEmpresa.customer_id == '612415621c9f31faeee98ff9'
          "
          @click="drawer = false"
          link
          to="/HistorialCargas"
        >
          <v-list-item-action>
            <v-icon>mdi-cloud-upload</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Historial de Cargas</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="user.datosFb.firebaseRTDB == true"
          @click="drawer = false"
          link
          to="/HistorialCargas"
        >
          <v-list-item-action>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Historial de Pedidos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-else @click="drawer = false" link to="/dashboard">
          <v-list-item-action>
            <v-icon>mdi-monitor-dashboard</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Historial de Pedidos</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="
            (user.datosFb.perfil != 'propios') &
            (user.datosFb.perfil != 'cecos')
          "
          @click="drawer = false"
          link
          to="/crud"
        >
          <v-list-item-action>
            <v-icon>mdi-account-cog</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Administración de Usuarios</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          v-if="
            (user.datosFb.perfil != 'propios') &
            (user.datosFb.perfil != 'cecos')
          "
          @click="drawer = false"
          to="/crudcompanies"
        >
          <v-list-item-action>
            <v-icon>mdi-account-details</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title
              >Administración de Perfiles Empresas</v-list-item-title
            >
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="drawer = false" link to="/account">
          <v-list-item-action>
            <v-icon>mdi-account</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Mi cuenta</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item @click="drawer = false" link to="/PreguntasFrecuentes">
          <v-list-item-action>
            <v-icon>mdi-forum</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Preguntas Frecuentes</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item
          @click="drawer = false"
          link
          target="_blank"
          href="https://rayoapp.com"
        >
          <v-list-item-action>
            <v-icon>mdi-flash</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>Acerca de Rayo</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
        <v-list-item v-if="user.datosFb.thor" @click="drawer = false">
          <v-list-item-action>
            <v-icon>mdi-hammer</v-icon>
          </v-list-item-action>
          <v-list-item-content>
            <v-list-item-title>FM</v-list-item-title>
          </v-list-item-content>
        </v-list-item>
      </v-list>
      <p
        class="caption"
        v-if="
          user != undefined &&
          user.datosFb != undefined &&
          user.datosFb.perfil != 'propios' &&
          user.datosFb.perfil != 'cecos'
        "
      >
        <!-- Solo admins: {{ this.fcmToken }} -->
      </p>
      <div v-if="user.data != null" class="pa-2">
        <v-btn color="#311def" dark @click="cerrarSesion()" block
          >Cerrar Sesión</v-btn
        >
      </div>
    </v-navigation-drawer>
    <!-- FIN BARRA DE NAVEGACION -->

    <!-- APP-BAR -->
    <v-app-bar
      v-if="['login'].indexOf($route.name) === -1"
      app
      clipped-left
      dense
    >
      <v-app-bar-nav-icon @click="drawer = !drawer" />
      <!-- <v-img max-width="25" src="https://rayoapp.com/assetsnew/images/copia-de-logo-3-128x127.png"></v-img> -->
      <v-img
        contain
        max-width="120"
        src="https://www.rayoapp.com/img/2020/logo_azul.png"
      ></v-img>

      <!-- <v-toolbar-title class="px-2">Pedidos Rayo:{{this.user.loggedIn }}</v-toolbar-title> -->
      <!-- <v-toolbar-title class="px-2">Rayo Delivery</v-toolbar-title> -->

      <v-spacer></v-spacer>

      <span v-if="user.photoURL != null">
        <v-img contain max-width="80" :src="user.photoURL"></v-img>
      </span>

      <span
        v-else-if="
          user.perfilEmpresa != null && user.perfilEmpresa.logo != null
        "
      >
        <v-img contain max-width="50" :src="user.perfilEmpresa.logo"></v-img>
      </span>

      <span v-else>
        <v-icon color="primary">mdi-account-circle</v-icon>
      </span>

      <!-- <span>
        <v-avatar
          tile
          @click="drawer = !drawer"
          size="34px"
          v-if="user.photoURL != null"
        >
          <v-img alt="Avatar" :src="user.photoURL"></v-img>
        </v-avatar>
        <v-avatar
          class="ma-2"
          tile
          @click="drawer = !drawer"
          size="34px"
          v-else-if="
            user.perfilEmpresa != null && user.perfilEmpresa.logo != null
          "
        >
          <v-img alt="Avatar" :src="user.perfilEmpresa.logo"></v-img>
        </v-avatar>

        <v-avatar @click="drawer = !drawer" size="34px" v-else>
          <v-icon color="primary">mdi-account-circle</v-icon>
        </v-avatar>
      </span> -->
      <span
        v-if="user.dataFb != null && user.dataFb.displayName != undefined"
        class="overline mb-4"
        >{{ user.dataFb.displayName }}</span
      >
      <!-- <v-btn icon @click="$vuetify.theme.dark = !$vuetify.theme.dark">
            <v-icon>mdi-brightness-4</v-icon>
        </v-btn> -->
    </v-app-bar>
    <!-- FIN APP BAR -->

    <!-- CONTENIDO PRINCIPAL -->
    <v-main>
      <!-- Provides the application the proper gutter -->
      <v-container fluid class="mx-0 px-0">
        <router-view></router-view>
      </v-container>
    </v-main>
    <!-- FIN CONTENIDO PRINCIPAL -->

    <!-- FOOTER -->
    <v-footer style="color: white" color="#122337" app>
      <span>
        <a style="color: white" href="https://rayoapp.com">Rayo LATAM</a> 2023 -
        v1.45
      </span>
    </v-footer>
    <!-- FIN FOOTER -->
  </v-app>

  <!-- :color="barColor" -->
  <!-- <v-app id="inspire"> -->

  <!-- <v-app-bar v-if="['login'].indexOf($route.name) === -1  || ['/'].indexOf($route.name) === -1 " app clipped-left> -->

  <!-- APP-BAR -->
  <!-- <v-app-bar
      v-if="
        ['ingreso'].indexOf($route.name) === -1 &&
          ['login'].indexOf($route.name) === -1
      "
      app
      clipped-left
    >
      <v-app-bar-nav-icon @click.stop="drawer = !drawer" />
      
      <v-img
        contain
        max-width="120"
        class="pa-2"
        src="https://www.rayoapp.com/img/2020/logo_azul.png"
      ></v-img>

      <v-spacer></v-spacer>

      <span>
        <v-avatar
          tile
          @click="drawer = !drawer"
          size="34px"
          v-if="user.photoURL != null"
        >
          <v-img alt="Avatar" :src="user.photoURL"></v-img>
        </v-avatar>
        <v-avatar
          class="ma-2"
          tile
          @click="drawer = !drawer"
          size="34px"
          v-else-if="
            user.perfilEmpresa != null && user.perfilEmpresa.logo != null
          "
        >
          <v-img alt="Avatar" :src="user.perfilEmpresa.logo"></v-img>
        </v-avatar>

        <v-avatar @click="drawer = !drawer" size="34px" v-else>
          <v-icon color="primary">mdi-account-circle</v-icon>
        </v-avatar>
      </span>
      <span
        v-if="user.dataFb != null && user.dataFb.displayName != undefined"
        class="overline mb-4"
        >{{ user.dataFb.displayName }}</span
      >
      
    </v-app-bar> -->
  <!-- FIN APP BAR -->

  <!-- CONTENIDO Y RUTAS -->
  <!-- <v-content>
      
      <v-container pa-0 fluid>
        <v-row justify="center"></v-row>

        <router-view></router-view>
      </v-container>
    </v-content> -->
  <!-- FIN CONTENIDO Y RUTAS -->

  <!-- FOOTER -->
  <!-- <v-footer style="color:white" color="#311eee" app>
      <span>
        <a style="color:white" href="https://rayoapp.com">Rayo LATAM</a> 2020 -
        v0.067
      </span>
    </v-footer> -->
  <!-- FIN FOOTER -->
  <!-- </v-app> -->
</template>

<script>
// import wb from "./registerServiceWorker";
import { mapGetters } from "vuex";
import firebase from "firebase";

// const configOptions = {
//   apiKey: 'AIzaSyDlXXRykQOlEvZmrJW_lIiFz7AqdsiiZjk',
//   authDomain: 'entregasrayo.firebaseapp.com',
//   databaseURL: 'https://entregasrayo.firebaseio.com',
//   projectId: 'entregasrayo',
//   storageBucket: 'entregasrayo.appspot.com',
//   messagingSenderId: '338070330126',
//   appId: '1:338070330126:web:cd4922b024d627e33d955f',
//   measurementId: 'G-RGB5HJ0B1Q'
// }

// firebase.initializeApp(configOptions)

export default {
  props: {
    source: String,
  },
  data: () => ({
    fcmToken: "",
    textoDialogoGenerico: "",
    tituloDialogoGenerico: "",
    destinoDialogoGenerico: "",

    dialogoGenerico: false,

    drawer: false,
    markers: [1, 2],
    // barColorLight: "#232222",
    // barColorDark: "#ffffff",

    fecha: "Bienvenid@ a Rayo",
    numerouser: 0,
  }),
  methods: {
    showPendingAlert() {
      this.alertaGenerica(
        "Tu Cuenta Aún No Está Activa.",
        "Acontinuación serás redireccionad@ a tu sección de perfil en donde podrás ingresar alguna información faltante para que puedas comenzar a usar Rayo.",
        "this.$router.push({name: 'account'})"
      );
    },
    alertaGenerica(titulo, texto, destino) {
      this.textoDialogoGenerico = texto;
      this.tituloDialogoGenerico = titulo;
      this.destinoDialogoGenerico = destino;
      //this.formNewEmailPassword = false;
      this.dialogoGenerico = true;
    },
    cerrarSesion() {
      var self = this;
      console.log("inicia cierre sesion");
      firebase
        .auth()
        .signOut()
        .then(() => {
          console.log("session closed");
          self.drawer = false;
          // self.$store.dispatch("fetchUser", null);
          // console.log(self.$router.currentRoute.name);
          if (self.$router.currentRoute.name == "login") {
            console.log("stay here");
          } else {
            self.$router.push({
              name: "login",
            });
          }
        });
    },
    goDark: () => {
      $vuetify.theme.dark = !$vuetify.theme.dark;
    },
  },
  created() {
    this.$vuetify.theme.dark = false;
  },
  mounted() {
    console.log("mounted app.vue");
    // console.log(firebase);
    // // const messaging = firebase.messaging();

    // // messaging
    // //     .requestPermission()
    // //     .then(() => {
    // //         //  alert('test2')
    // //         console.log("Notification permission granted.");

    // //         // Get Token
    // //         messaging.getToken().then((token) => {
    // //             // alert(token)
    // //             //      alert('test3 ')
    // //             this.fcmToken = token;
    // //             console.log(token);
    // //         });
    // //     })
    // //     .catch((err) => {
    // //         // alert(
    // //         // 	"No pudo conseguir acceso. Para recibir notifciaciones Push debes dar acceso a Rayo Delivery."
    // //         // );
    // //         console.log("Unable to get permission to notify.", err);
    // //     });

    // // messaging.onMessage((payload) => {
    // //     console.log("Message received. ", payload);
    // //     // ...
    // // });
  },

  computed: {
    ...mapGetters({
      user: "user",
    }),

    // barColor() {
    //   if (this.$vuetify.theme.dark) {
    //     return this.barColorDark;
    //   } else {
    //     return this.barColorLight;
    //   }
    // }
  },
  // arreglo: function() {
  //   var xx = user.dataFb;
  //   Object.keys(xx).map(function(key) {
  //     return [Number(key), xx[key]];
  //   });
  // }
};
</script>
